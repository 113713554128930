import emoji from "react-easy-emoji";

import googlelogo from "./assets/img/icons/common/google.svg";
import github from "./assets/img/icons/common/github.svg";
import airbnb from "./assets/img/icons/common/airbnbLogo.png";
import whiterabbitlogo from "./assets/img/icons/common/WhiteRabbitParticle.png";

export const greetings = {
  name: "Arun Prakash",
  title: "Greetings 🤝, I am Arun Prakash",
  description:
    "Emerging from the picturesque terrains of Kerala, I excel in Mobile & Desktop Application Development. With proficiency in Flutter & Xamarin.Forms Frameworks, I've also engineered production-ready libraries.",
  resumeLink: "/resume.pdf",
};

export const openSource = {
  githubUserName: "ArunPrakashG",
};

export const socialLinks = {
  facebook: "https://www.facebook.com/i.arunprakash",
  instagram: "https://www.instagram.com/i.arunprakash",
  twitter: "https://twitter.com/_arunprakash_",
  github: "https://github.com/ArunPrakashG",
  linkedin: "https://www.linkedin.com/in/arunprakashg/",
};

export const skillsSection = {
  title: "Professional Capabilities 🛠️",
  subTitle: "Breathing Life into Ideas 🌟",
  skills: [
    emoji(
      "⚡ Designing & launching interactive cross-platform applications, integrated with third-party magic like Firebase."
    ),
    emoji(
      "⚡ Architecting robust, scalable, and modular libraries in both C# and Dart."
    ),
    emoji(
      "⚡ Crafting sharp web scrapers & scripts tailored for meticulous data analysis and streamlined automation."
    ),
  ],

  softwareSkills: [
    // ... this section typically contains software skills with font-awesome or similar icons; I'll assume it's unchanged from your original
    {
      skillName: "flutter",
      fontAwesomeClassname: "logos:flutter",
    },
    {
      skillName: "mysql",
      fontAwesomeClassname: "vscode-icons:file-type-sql",
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "logos:firebase",
    },
    {
      skillName: "git",
      fontAwesomeClassname: "logos:git-icon",
    },
    {
      skillName: "csharp",
      fontAwesomeClassname: "logos:c-sharp",
    },
    {
      skillName: "dart",
      fontAwesomeClassname: "logos:dart",
    },
    {
      skillName: "xamarin",
      fontAwesomeClassname: "logos:xamarin",
    },
    {
      skillName: "rest-api",
      fontAwesomeClassname: "dashicons:rest-api",
    },
    {
      skillName: "dotnet",
      fontAwesomeClassname: "cib:dot-net",
    },
    {
      skillName: "wordpress",
      fontAwesomeClassname: "bi:wordpress",
    },
    {
      skillName: "iot",
      fontAwesomeClassname: "fluent:iot-24-regular",
    },
    {
      skillName: "android",
      fontAwesomeClassname: "akar-icons:android-fill",
    },
    {
      skillName: "web-scrapping",
      fontAwesomeClassname: "logos:chrome",
    },
    {
      skillName: "json",
      fontAwesomeClassname: "bx:bxs-file-json",
    },
    {
      skillName: "cli",
      fontAwesomeClassname: "grommet-icons:cli",
    },
  ],
};

export const SkillBars = [
  {
    Stack: "Backend Mastery 🎯",
    progressPercentage: "90",
  },
  {
    Stack: "Frontend Brilliance 🖥️",
    progressPercentage: "50",
  },
  {
    Stack: "Dart/Flutter/Firebase Expertise 🚀",
    progressPercentage: "90",
  },
  {
    Stack: "C#/.NET Acumen 🛡️",
    progressPercentage: "90",
  },
  {
    Stack: "C#/ASP.NET REST APIs Craftsmanship 🔧",
    progressPercentage: "70",
  },
  {
    Stack: "Xamarin.Forms Prowess 📱",
    progressPercentage: "80",
  },
  {
    Stack: "PHP/Wordpress Know-how 🌐",
    progressPercentage: "60",
  },
];

export const educationInfo = [
  {
    schoolName: "St. Thomas College",
    subHeader: "Bachelor of Computer Applications",
    duration: "2018 - 2021",
    descBullets: [
      "Conceived an avant-garde Email System harnessing the synergy of HTML, JS, CSS, PHP, and MySQL.",
      "Engineered a BSNL Tracker application, first with C# and Xamarin.Forms, later metamorphosing it with the wings of Flutter.",
    ],
  },
  {
    schoolName: "Mount Bethany H.S.S",
    subHeader: "Higher Secondary Education",
    duration: "2016 - 2018",
  },
  {
    schoolName: "Holy Angels Model School",
    subHeader: "Secondary Education",
    duration: "2013 - 2015",
  },
];

export const experience = [
  {
    role: "Senior Software Engineer",
    company: "White Rabbit Group",
    companylogo: googlelogo,
    date: "2023 – Present",
    desc: "Worked on various projects and scripts and learned new languages and frameworks",
    descBullets: ["Developed various other libraries in different languages."],
  },
  {
    role: "Software Engineer",
    company: "White Rabbit Group",
    companylogo: googlelogo,
    date: "2021 – 2023",
    desc: "Worked on various projects and scripts and learned new languages and frameworks",
    descBullets: ["Developed various other libraries in different languages."],
  },
  {
    role: "Freelance Application Developer",
    company: "Independent Freelancer",
    companylogo: googlelogo,
    date: "2019 – 2021",
    desc: "Dove deep into diverse projects, constantly refining my skills across various languages and frameworks.",
    descBullets: [
      "Orchestrated 'wordpress_client', a Dart library for impeccable integration with Wordpress REST API.",
      "Initiated, designed, and brought to life an array of libraries spanning various programming languages.",
    ],
  },
];

export const projects = [
  {
    name: "WordpressClient",
    desc: "An elite Dart library meticulously tailored for Wordpress REST API integration. Sculpted from the base, offering a user-friendly experience, and grounded on the robustness of the WordpressCore C# Library.",
    github: "https://github.com/ArunPrakashG/wordpress_client",
  },
  {
    name: "BSNL Tracker",
    desc: "BSNL Tracker is a free application which helps to know your FTTH usage, bills, bill history, topup history etc with a clean & fast UI.",
    link: "https://play.google.com/store/apps/details?id=bsnlfibertracker.app",
  },
  {
    name: "MGU Result Checker",
    desc: "MGU Result checker simplifys the process of checking MGU exam results into a two step process and automates retry mechanisms if the request fails.",
    link: "https://play.google.com/store/apps/details?id=com.synnetic.resultchecker",
  },
  {
    name: "Luna - Digital Assistant",
    desc: "Luna is a digital assistant designed to run on Raspberry Pi which is capable of setting reminders, controlling lights etc (via GPIO) , She can crack jokes, quotes, daily messages etc.",
    github: "https://github.com/ArunPrakashG/Luna",
  },
  {
    name: "Byte Converter",
    desc: "Byte Converter is a simple library written in Dart which converts bytes to other units like KB, MB, GB, TB, PB, EB, ZB, etc",
    github: "https://github.com/ArunPrakashG/byte_converter",
  },
];
